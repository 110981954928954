<template>
    <div id="printPlanning">
        <div v-for="user in users" class="customers">
            <h1 class="text-center" style="line-height: normal;">{{ operation.name }}</h1>
            <h2 class="text-center mb-0" style="display: block;">{{ user.title }}</h2>
            
            <table class="customersTable">
                <tr>
                    <th>{{ $t('appointment') }}</th>
                    <th>{{ $t('customer') }}</th>
                    <th v-for="type in demandTypes">
                        {{ $t(type.name) }}
                    </th>
                </tr>
                <template v-for="date in user.dates">
                    <tr>
                        <td :colspan="(2 + Object.keys(demandTypes).length)" class="text-center" style="background-color: rgba(167,167,167,0.65);">{{ capitalizeFirstLetter(timestampToDate(new Date(date.date.split(' ')[0]).getTime() / 1000, 'dayMonthYear')) }}</td>
                    </tr>
                    <tr v-for="appointment in date.appointments">
                        <td>
                            <strong>{{ getHourMinutesFromDate(appointment.date_start) }} - {{ getHourMinutesFromDate(appointment.date_end) }}</strong>
                            <template v-if="appointment.visio == '1'">
                                <br>
                                <v-icon dense :color="'primary'">$webcam_d</v-icon>
                            </template>
                        </td>
                        <td>
                            <strong>{{ appointment.contact_id.firstname }} {{ appointment.contact_id.name }}</strong><br>
                            <span v-for="phone in appointment.contact_id.phones">
                                {{ phone.number }}<br>
                            </span>
                            {{ appointment.contact_id.email ? appointment.contact_id.email.toLowerCase() : '' }}
                        </td>
                        <td v-for="type in demandTypes">
                            <div v-for="demand in getDemandsByType(appointment.demands, type.name)" class="mb-4">
                                <!-- !!! Demande de type Achat !!! -->
                                <div v-if="type.name === 'sell'">
                                    <h3 class="mb-2">{{ demand.name }}</h3>

                                    <ul class="pl-0" style="list-style:none;">
                                        <li v-if="demand.univers_id && demand.univers_id.label"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('univers')}} :</b> <span class="black--text">{{ demand.univers_id.label }}</span></p>
                                        </li>
                                        <li v-if="demand.details && demand.details.desiredFunding"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('desiredFunding')}} :</b> <span class="black--text">{{ demand.details.desiredFunding }}</span></p>
                                        </li>
                                        <li v-if="demand.details && demand.details.desiredFunding && demand.details.desiredFunding === $t('yes') && demand.details.fundingType"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('fundingType')}} :</b> <span class="black--text">{{ demand.details.fundingType }}</span></p>
                                        </li>
                                        <li v-if="demand.details && demand.details.desiredFunding && demand.details.desiredFunding === $t('yes') && demand.details.fundingContribution"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('fundingContribution')}} :</b> <span class="black--text">{{ demand.details.fundingContribution }} €</span></p>
                                        </li>
                                        <li v-if="demand.amount"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('amount')}} :</b> <span class="black--text">{{ demand.amount }} €</span></p>
                                        </li>
                                        <li v-if="demand.details && demand.details.comment"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('comment')}} :</b> <span class="black--text">{{ demand.details.comment }}</span></p>
                                        </li>
                                    </ul>
                                    <template v-if="demand.criteria && demand.criteria.length">
                                        <p class="mb-0 d-inline"><b>{{$t('criteria')}} :</b></p>
                                        <ul class="mt-3" style="list-style: none;">
                                            <li v-for="(criteria, index) in demand.criteria" :key="index" class="mt-2" style="position: relative;" v-if="criteria.reference && criteria.value">
                                                <p class="mb-0 d-inline"><v-icon x-small color="grey" style="position: absolute; left: -23px; top: 3px;">$circle_s</v-icon><b>{{ criteria.reference }} :</b></p>
                                                <p class="mb-0 ml-2 d-inline" v-if="criteria.value.text">{{criteria.value.text}}</p>
                                                <p class="mb-0 ml-2 d-inline" v-else>{{criteria.value}}</p>
                                            </li>
                                        </ul>
                                    </template>
                                </div>
                                <!-- !!! Demande de type Reprise !!! -->
                                <div v-else-if="type.name === 'reprise'">
                                    <h3 class="mb-2">{{ demand.name }}</h3>

                                    <ul class="pl-0" style="list-style:none;">
                                        <li v-if="demand.item_id && demand.item_id.label"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('item')}} :</b> <span class="black--text">{{ demand.item_id.label }}</span></p>
                                        </li>
                                        <li v-if="demand.amount"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('amount')}} :</b> <span class="black--text">{{ demand.amount }} €</span></p>
                                        </li>
                                        <li v-if="demand.details && demand.details.comment"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('comment')}} :</b> <span class="black--text">{{ demand.details.comment }}</span></p>
                                        </li>
                                    </ul>
                                </div>
                                <!-- !!! Demande de type SAV !!! -->
                                <div v-else-if="type.name === 'sav'">
                                    <h3 class="mb-2">{{ demand.name }}</h3>

                                    <ul class="pl-0" style="list-style:none;">
                                        <li v-if="demand.univers_id && demand.univers_id.label"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('univers')}} :</b> <span class="black--text">{{ demand.univers_id.label }}</span></p>
                                        </li>
                                        <li v-if="demand.item_id && demand.item_id.label"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('item')}} :</b> <span class="black--text">{{ demand.item_id.label }}</span></p>
                                        </li>
                                        <li v-if="demand.amount"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('amount')}} :</b> <span class="black--text">{{ demand.amount }} €</span></p>
                                        </li>
                                        <li v-if="demand.details && demand.details.comment"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('comment')}} :</b> <span class="black--text">{{ demand.details.comment }}</span></p>
                                        </li>
                                    </ul>
                                </div>
                                <!-- !!! Demande de type Autre !!! -->
                                <div v-else-if="type.name === 'other'">
                                    <h3 class="mb-2">{{ demand.name }}</h3>

                                    <ul class="pl-0" style="list-style:none;">
                                        <li v-if="demand.univers_id && demand.univers_id.label"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('univers')}} :</b> <span class="black--text">{{ demand.univers_id.label }}</span></p>
                                        </li>
                                        <li v-if="demand.amount"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('amount')}} :</b> <span class="black--text">{{ demand.amount }} €</span></p>
                                        </li>
                                        <li v-if="demand.details && demand.details.comment"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('comment')}} :</b> <span class="black--text">{{ demand.details.comment }}</span></p>
                                        </li>
                                    </ul>
                                </div>
                                <!-- !!! Demande de type Abonnement !!! -->
                                <div v-else-if="type.name === 'subscription'">
                                    <h3 class="mb-2">{{ demand.name }}</h3>

                                    <ul class="pl-0" style="list-style:none;">
                                        <li v-if="demand.operation_id && demand.operation_id.label"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('operation')}} :</b> <span class="black--text">{{ demand.operation_id.label }}</span></p>
                                        </li>
                                        <li v-if="demand.retroplanning_id && demand.retroplanning_id.name"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('retroplanning')}} :</b> <span class="black--text">{{ demand.retroplanning_id.name }}</span></p>
                                        </li>
                                        <li v-if="demand.entity_id && demand.entity_id.label"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('entity')}} :</b> <span class="black--text">{{ demand.entity_id.label }}</span></p>
                                        </li>
                                        <li v-if="demand.univers_id && demand.univers_id.label"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('univers')}} :</b> <span class="black--text">{{ demand.univers_id.label }}</span></p>
                                        </li>
                                        <li v-if="demand.details && demand.details.formule"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('formule')}} :</b> <span class="black--text">{{ demand.details.formule }}</span></p>
                                        </li>
                                        <li v-if="demand.amount"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('amount')}} :</b> <span class="black--text">{{ demand.amount }} €</span></p>
                                        </li>
                                        <li v-if="demand.details && demand.details.interests"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('FIELDDetailsInterests')}} :</b> <span class="black--text">{{demand.details.interests.map(e => e.text).join(", ")}}</span></p>
                                        </li>
                                        <li v-if="demand.details && demand.details.comment"  style="position:relative;">
                                            <p class="mb-0 d-inline"><b>{{$t('comment')}} :</b> <span class="black--text">{{ demand.details.comment }}</span></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import dateManipulation from '@/mixins/dateManipulation';

export default {
    name: "printPlanningComponent",
    mixins:[dateManipulation],
    data() {
        return {
            operation: {},
            demandTypes: [],
            users: [],
        }
    },
    created() {
        GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/get').then((response) => {
            this.operation = response.data.data;
        });
        GenericDataService.getData('hard_value/getValuesByType?type=demand_type').then((response) => {
            this.demandTypes = response.data.data;
        });
        GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/getRdvListForPrint').then((response) => {
            this.users = response.data.data;
        });
    },
    methods: {
        getDemandsByType(demands, type) {
            return demands.filter(demand => demand.type === type);
        },
        capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1);
        }
    }
}
</script>

<style lang="scss">
@media print {
    #containerView, .container-menu, .baseHeaderDiv {
        height: auto !important;
        overflow: visible !important;
    }

    @page {
        margin: 1cm;
        size: portrait;
    }

    .customersTable {
        border-collapse: collapse;
        width: 100%;
        font-size: 12px;
    }

    .customersTable td, .customersTable th {
        border: 1px solid #ddd;
        padding: 8px;
        line-height: 22px;
        max-width: 250px;
    }

    .customersTable tr:nth-child(even){ background-color: #f2f2f2; }

    .customersTable tr:hover { background-color: #ddd; }

    .customersTable th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #a7a7a7;
        color: white;
    }

    body {
        -webkit-print-color-adjust: exact;
    }

    header, .menuCss, .subHeader, .configureBtn, .multiMenuContent, .container {
        display: none !important;
    }

    #app-content {
        width: 100% !important;
    }

    .customers {
        page-break-after: always;
    }

    /****** PRINT RAPPORT IMPORT ******/
    #pdf_div {
        display: block !important;
    }

    #app, .baseHeader {
        background-color: white !important;
    }
}
</style>